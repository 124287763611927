import React from "react";

import "reactflow/dist/style.css";
import "@/globals.css";

// import { RouterProvider } from "react-router-dom";
import { ReactFlowProvider } from "reactflow";

import { TooltipProvider } from "@/components/ui/tooltip.tsx";
import { env } from "@/env";
import { initMonitoring } from "@/lib/monitoring.ts";
import { AuthProvider } from "@/providers/AuthProvider";
import { ThemeProvider } from "@/providers/ThemeProvider";

// import { router } from "@/router.tsx";

import { Toaster } from "./components/ui/toaster";
import { DevTools } from "./DevTools";
import { AnalyticsProvider } from "./providers/AnalyticsProvider";
import { QueryProvider } from "./providers/QueryProvider";

initMonitoring(); // Sentry initialization

export const Main = ({ routerProvider }: { routerProvider: JSX.Element }) => {
  return (
    <React.StrictMode>
      <AuthProvider>
        <AnalyticsProvider>
          {/* <AnalyticsProvider disabled={env.VITE_PUBLIC_NODE_ENV !== "production"}> */}
          <ThemeProvider defaultTheme="dark" storageKey="vite-ui-theme">
            <QueryProvider>
              <ReactFlowProvider>
                <TooltipProvider delayDuration={200}>
                  {env.VITE_PUBLIC_NODE_ENV !== "production" && <DevTools />}
                  <Toaster />
                  {/* <RouterProvider router={router} /> */}
                  {routerProvider}
                </TooltipProvider>
              </ReactFlowProvider>
            </QueryProvider>
          </ThemeProvider>
        </AnalyticsProvider>
      </AuthProvider>
    </React.StrictMode>
  );
};
