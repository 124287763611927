import { useCallback, useState } from "react";
import { useSuspenseQuery } from "@tanstack/react-query";
import { AnimatePresence, motion } from "framer-motion";
import { useNavigate, useParams } from "react-router-dom";

import { Icons } from "@/components/Icons";
import { Card, CardContent, CardHeader } from "@/components/ui/card";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { CreateSubmissionMutation } from "@/gql/graphql";
import { cn } from "@/lib/cn";
import {
  getVendorSubmissionTypesQueryOptions,
  OrgSubmissionType,
} from "@/lib/hooks/queries/SubmissionTypes";

import { useProcessSubmission } from "../../../../lib/hooks/mutations/useProcessSubmission";
import { detectMappingChanges } from "./detectMappingChanges";
import { FileUpload } from "./FileUpload";
import { useGetNormalizeVendorMapping } from "./useGetNormalizeVendorMappings";
import { useUploadFileAndCreateSubmission } from "./useUploadFileAndCreateSubmission";
import {
  getWorksheet,
  getWorksheetHeaders,
  loadWorkbookFromBuffer,
} from "@/lib/fileUtils";
import { toast } from "sonner";
import { Muted, P } from "@/components/ui/typography";

export const SubmissionTypeUploadGrid = () => {
  const { vendorId: vId } = useParams();
  const vendorId = Number(vId);
  const navigate = useNavigate();

  const { data: submissionTypeData } = useSuspenseQuery(
    getVendorSubmissionTypesQueryOptions(vendorId),
  );
  const vendorSubmissionTypes =
    submissionTypeData.vendor?.submissionTypes ?? [];
  let [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  const [file, setFile] = useState<File | undefined>(undefined);
  const { mutate: uploadFileAndCreateSubmission } =
    useUploadFileAndCreateSubmission();
  const { mutate: processSubmission } = useProcessSubmission();

  const { data: mappings } = useGetNormalizeVendorMapping({ vendorId });

  const checkMappingAndCreateSubmission = useCallback(
    async (file: File, submissionType: OrgSubmissionType) => {
      // given that you know which submissionType is it, find the current mapping for this type
      const normalizeMapping = mappings?.vendor?.normalizeMappings.find(
        (m) => m.submissionType.id === submissionType.id,
      );
      const buffer = await file.arrayBuffer();
      // turn the file into an excelbook and check that the headers match the current mapping
      const workbook = await loadWorkbookFromBuffer(buffer);
      // if no workbook we have an error
      if (!workbook) {
        toast.error("Error loading file");
        return;
      }

      const worksheet = getWorksheet(workbook);
      const headers = getWorksheetHeaders(worksheet);

      // route to normalize if new mapping detected or no mapping exists
      const shouldRouteToNormalize = detectMappingChanges({
        normalizeMapping,
        headers,
      });

      if (shouldRouteToNormalize) {
        // pass the file into the state to be used in the Confirm-Normalize page
        navigate(
          `/vendor-submit/${vendorId}/confirm-mapping/${submissionType.id}`,
          {
            state: {
              worksheet,
              normalizeMapping,
              submissionType,
              file,
            },
          },
        );
      } else {
        uploadFileAndCreateSubmission(
          { file, submissionTypeId: submissionType.id },
          {
            onSuccess: (submissionData: CreateSubmissionMutation) => {
              const submissionId = submissionData.createSubmission.id;
              setFile(undefined);
              processSubmission(
                { submissionId },
                {
                  onSuccess: (data) => {
                    navigate(
                      `/vendor-submit/${vendorId}/submission/${submissionId}`,
                    );
                  },
                  onError: (error) => {
                    console.error("onProcessSubmission error", error);
                  },
                },
              );
            },
            onError: (error) => {
              console.error("onUpload error", error);
              setFile(undefined);
            },
          },
        );
      }
    },
    [mappings, processSubmission, uploadFileAndCreateSubmission, vendorId],
  );

  return (
    <div className="grid grid-flow-col">
      {vendorSubmissionTypes.map((submissionType, idx) => {
        const onUpload = (file: File) =>
          checkMappingAndCreateSubmission(file, submissionType);

        return (
          <Dialog key={submissionType.id}>
            <DialogTrigger>
              <div
                data-testid={`upload-${submissionType.type}`}
                className="group relative block h-full w-full p-2 "
                onMouseEnter={() => setHoveredIndex(idx)}
                onMouseLeave={() => setHoveredIndex(null)}
                key={submissionType.id}
              >
                <AnimatePresence>
                  {hoveredIndex === idx && (
                    <motion.span
                      className="absolute inset-0 block h-full w-full rounded-3xl bg-accent"
                      layoutId="hoverBackground"
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        transition: { duration: 0.15 },
                      }}
                      exit={{
                        opacity: 0,
                        transition: { duration: 0.1, delay: 0.66 },
                      }}
                    />
                  )}
                </AnimatePresence>
                <Card
                  className={cn(
                    "relative z-20 h-72 cursor-pointer transition-all duration-200",
                    "hover:border-primary/20 hover:shadow-lg",
                    "flex flex-col items-center justify-center p-6",
                  )}
                >
                  <CardHeader className="flex flex-col items-center space-y-2 pb-2">
                    {/* TODO: need icon on submissiontypes */}
                    {idx === 0 ? (
                      <Icons.package className="mb-4 h-12 w-12" />
                    ) : (
                      <Icons.invoice className="mb-4 h-12 w-12" />
                    )}
                    <P className="text-xl font-semibold ">
                      {submissionType.id === 1 ? "Inventory" : "POS"}
                    </P>
                  </CardHeader>
                  <CardContent className="text-center">
                    <Muted className="">
                      {`Click to upload your ${submissionType.name.toLowerCase()} file`}
                    </Muted>
                  </CardContent>
                </Card>
              </div>
            </DialogTrigger>
            <DialogContent>
              <DialogTitle>Upload {submissionType.name}</DialogTitle>
              <FileUpload
                setFileToUpload={setFile}
                file={file}
                onUpload={onUpload}
                isUploading={false}
              />
            </DialogContent>
          </Dialog>
        );
      })}
    </div>
  );
};
