import { useCallback, useMemo, useState } from "react";
import { GridReadyEvent, RowClickedEvent } from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";

import { cn } from "@/lib/cn";

import { CustomLoadingOverlay } from "../submissions-table/CustomLoadingOverlay";
import { CustomNoRowsOverlay } from "../submissions-table/CustomNoRowsOverlay";
import { Filter } from "../table/Filter";
import { useTableFilterContext } from "../table/TableFiltersContext";
import { TableFiltersPopover } from "../table/TableFiltersPopover";
import { TablePagination } from "../table/TablePagination";
import { VendorNameFilter } from "./VendorNameFilter";
import { getVendorsTableRows, VendorRowData } from "./getVendorsTableRows";
import { useGetVendorsTableColDefs } from "./useGetVendorsTableColDefs";
import { useGetVendorsInfinite } from "./useGetVendorsInfinite";
import { useNavigate } from "react-router-dom";

type Props = {
  customHeight?: string;
};

export const VENDORS_TABLE_PAGINATION_SIZE = 20;

export const VendorsTable = ({ customHeight = "h-[800px]" }: Props) => {
  const navigate = useNavigate();
  const { activeFilters, getSearchVars } = useTableFilterContext();

  const searchVars = getSearchVars();

  const [gridApi, setGridApi] = useState<AgGridReact["api"]>();

  const { data, isLoading, fetchNextPage, hasNextPage } = useGetVendorsInfinite(
    { searchVars },
  );

  const vendors = useMemo(() => {
    return data?.pages?.flatMap((page) => page.vendors) ?? [];
  }, [data]);

  const { rows } = getVendorsTableRows({ vendors });

  const colDefs = useGetVendorsTableColDefs({ vendors });

  const loadingOverlayComponent = useMemo(() => {
    return CustomLoadingOverlay;
  }, []);
  const loadingOverlayComponentParams = useMemo(() => {
    return {
      loadingMessage: "One moment please...",
    };
  }, []);

  const noRowsOverlayComponent = useMemo(() => {
    return CustomNoRowsOverlay;
  }, []);

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      loadingMessage: "Oops! No vendors found.",
    };
  }, []);

  const onGridReady = useCallback(async (params: GridReadyEvent) => {
    setGridApi(params.api);
  }, []);

  const onRowClick = (e: RowClickedEvent<VendorRowData>) => {
    const rowData = e.data;
    if (!rowData) return;
    // navigate to vendor page or show vendor editing dialog
    navigate(`/dashboard/partner/${rowData.ID}`);
  };

  // const onClose = () => {
  // setSelectedRow(undefined);
  // };

  return (
    <>
      <div
        id="transformation-table"
        className={cn("ag-theme-custom cell-error flex flex-col", customHeight)}
      >
        <div className="flex flex-row items-center justify-between gap-4 pb-2">
          <div className="flex gap-2">
            <VendorNameFilter />
            <TableFiltersPopover />
            <div className="flex items-center gap-2">
              {activeFilters.map((filter) => (
                <Filter key={filter.label} activeFilter={filter} />
              ))}
            </div>
          </div>
          <TablePagination
            gridApi={gridApi}
            fetchNextPage={fetchNextPage}
            hasNextPage={hasNextPage}
            paginationSize={VENDORS_TABLE_PAGINATION_SIZE}
            totalSubmissions={data?.pages[0]?.aggregateVendor._count?.id ?? 20}
          />
        </div>
        <AgGridReact
          onRowClicked={onRowClick}
          onGridReady={onGridReady}
          rowSelection="multiple"
          pagination={true}
          paginationPageSize={VENDORS_TABLE_PAGINATION_SIZE}
          suppressPaginationPanel={true}
          // onPaginationChanged={onPaginationChanged}
          suppressScrollOnNewData={true}
          rowData={isLoading ? undefined : rows}
          columnDefs={colDefs}
          loadingOverlayComponent={loadingOverlayComponent}
          loadingOverlayComponentParams={loadingOverlayComponentParams}
          noRowsOverlayComponent={noRowsOverlayComponent}
          noRowsOverlayComponentParams={noRowsOverlayComponentParams}
          reactiveCustomComponents
        />
      </div>
    </>
  );
};
