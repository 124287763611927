import { Suspense } from "react";
import {
  createSearchParams,
  Outlet,
  redirect,
  resolvePath,
  RouteObject,
} from "react-router-dom";
import { z } from "zod";
import {
  Submission,
  SubmissionStatus,
  TransformationStatus,
  Vendor,
} from "@/gql/graphql";
import { VendorSubmitPage } from "./:vendorId/home/VendorSubmitPage";
import { LoadingPage } from "./:vendorId/submission/:submissionId/LoadingPage";
import { VendorSubmissionLayout } from "./:vendorId/submission/:submissionId/VendorSubmissionLayout";
import { ConfirmMappingPageWrapper } from "./:vendorId/confirm-mapping/ConfirmMappingPageWrapper";
import { YourExtractionsPageWrapper } from "./:vendorId/submission/:submissionId/review/YourExtractionsPageWrapper";
import { SummaryPage } from "./:vendorId/submission/:submissionId/summary/SummaryPage";
import { ensureQueryData } from "@/lib/hooks/graphql";
import { getVendorSubmissionTypesQueryOptions } from "@/lib/hooks/queries/SubmissionTypes";
import { match, P } from "ts-pattern";
import { getSubmissionRouteQueryOptions } from "./:vendorId/submission/:submissionId/getSubmissionRouteQueryOptions";
import { getSubmissionNormalMapQueryOptions } from "./:vendorId/submission/:submissionId/normalize/getSubmissionNormalMapQueryOptions";
import { NormalizeMappingPageWrapper } from "./:vendorId/submission/:submissionId/normalize/NormalizeMappingPageWrapper";
import { EnrichmentPage } from "./:vendorId/submission/:submissionId/review/EnrichmentPage";
import { ValidationPage } from "./:vendorId/submission/:submissionId/review/validation/ValidationPage";
import { getDecodedJwtCookie } from "@/lib/hooks/Auth0";
import { UserRole } from "@/gql/graphql";

export enum SubmissionRoutes {
  Normalize = "normalize",
  Review = "review",
  Summary = "summary",
  Loading = "loading",
}

export enum ReviewRoutes {
  Validation = "validation",
}
const NORMAL_DIALOG_QUERY_PARAM = "normalize_dialog";
const NORMAL_CONFIG_CREATE = "create";
const NORMAL_CONFIG_UPDATE = "update";

export const vendorSubmitRoutes = {
  path: "vendor-submit",
  loader: async ({ request }: { request: Request }) => {
    // Only redirect if we're on the exact /vendor-submit path
    const url = new URL(request.url);
    if (url.pathname === "/vendor-submit") {
      const decodedJwt = getDecodedJwtCookie();

      if (decodedJwt === null) {
        return redirect("/login");
      }

      const role = decodedJwt.claims.role as UserRole;

      if (role !== UserRole.Partner && role !== UserRole.Developer) {
        return redirect("/unauthorized-partner");
      }

      // special routing for developer role
      if (role === UserRole.Developer) {
        return redirect("/vendor-submit/1");
      }

      const partnerId = decodedJwt.claims.partnerId;
      return redirect(`/vendor-submit/${partnerId}`);
    }

    return null;
  },
  element: <Outlet />,
  children: [
    {
      path: ":vendorId",
      loader: async ({ params }) => {
        const decodedJwt = getDecodedJwtCookie();

        if (decodedJwt === null) {
          return redirect("/login");
        }

        const userPartnerId = decodedJwt.claims.partnerId;
        const urlPartnerId = Number(params.vendorId);
        const role = decodedJwt.claims.role as UserRole;
        // Check if the vendorId in the URL matches the one in the JWT
        if (userPartnerId !== urlPartnerId && role !== UserRole.Developer) {
          console.error(
            "Unauthorized: User attempting to access different vendor's data",
          );
          return redirect("/unauthorized");
        }

        return null;
      },
      element: <Outlet />,
      children: [
        {
          path: "",
          loader: async ({ params, request }) => {
            let vendorId: Vendor["id"] = Number(params.vendorId);

            try {
              vendorId = z.number().parse(vendorId); // catches NaN
            } catch (e) {
              if (e instanceof z.ZodError)
                console.error("vendorId is not valid", e.errors);
              return redirect("/vendor-auth-messed-up");
            }

            ensureQueryData(getVendorSubmissionTypesQueryOptions(vendorId));

            return null;
          },
          element: (
            <Suspense>
              <VendorSubmitPage />
            </Suspense>
          ),
        },
        {
          path: "confirm-mapping",
          children: [
            {
              path: ":submissionTypeId",
              element: <ConfirmMappingPageWrapper />,
            },
          ],
          // element: <NormalizeMappingPageWrapper />,
        },
        {
          path: "submission",
          element: <VendorSubmissionLayout />,
          loader: async ({ params }) => {
            const vendorId: Vendor["id"] = Number(params.vendorId);

            ensureQueryData(getVendorSubmissionTypesQueryOptions(vendorId));

            if (!params.submissionId) {
              return redirect(`/vendor-submit/${params.vendorId}`);
            }
            return null;
          },
          children: [
            {
              path: ":submissionId",
              element: <Outlet />,
              // responsible for conditional routing of a particular submission
              loader: async ({ params, request }) => {
                const vendorId: Vendor["id"] = Number(params.vendorId);
                let submissionId: Submission["id"] = Number(
                  params.submissionId,
                );

                try {
                  submissionId = z.number().parse(submissionId);
                } catch (e) {
                  if (e instanceof z.ZodError)
                    console.error("submissionId is not valid", e.errors);
                  return redirect(`/vendor-submit/${params.vendorId}`);
                }

                ensureQueryData(
                  getSubmissionNormalMapQueryOptions({
                    submissionId,
                    vendorId,
                  }),
                );

                const data = await ensureQueryData(
                  getSubmissionRouteQueryOptions({
                    submissionId,
                    vendorId,
                  }),
                );

                const submission = data?.submission;

                const requestPath = new URL(request.url).pathname;
                const baseSubmissionPath = `/vendor-submit/${params.vendorId}/submission/${submissionId}`;

                return (
                  match(submission?.status)
                    .with(undefined, () => {
                      return redirect(`/vendor-submit/${params.vendorId}`);
                    })
                    .with(
                      P.union(SubmissionStatus.AwaitingPreprocessing),
                      () => {
                        const hasMapping = false;
                        const hasCorrectMapping = hasMapping && false;

                        if (!hasMapping || !hasCorrectMapping) {
                          const queryParams = createSearchParams({
                            [NORMAL_DIALOG_QUERY_PARAM]: hasMapping
                              ? NORMAL_CONFIG_UPDATE
                              : NORMAL_CONFIG_CREATE,
                          });

                          const { pathname } = resolvePath(
                            `${SubmissionRoutes.Normalize}?${queryParams}`,
                            baseSubmissionPath,
                          );

                          if (requestPath !== pathname)
                            return redirect(pathname);
                        } else {
                          const { pathname } = resolvePath(
                            SubmissionRoutes.Review,
                            baseSubmissionPath,
                          );

                          if (requestPath !== pathname)
                            return redirect(pathname);
                        }
                      },
                    )
                    .with(
                      P.union(
                        SubmissionStatus.Pending,
                        SubmissionStatus.Processing,
                      ),
                      () => {
                        const { pathname } = resolvePath(
                          SubmissionRoutes.Loading,
                          baseSubmissionPath,
                        );

                        if (requestPath !== pathname) return redirect(pathname);
                      },
                    )
                    .with(
                      P.union(
                        SubmissionStatus.Failed,
                        SubmissionStatus.Processed,
                        SubmissionStatus.VendorReview,
                      ),
                      () => {
                        const firstNotApprovedTransformation =
                          submission?.transformations?.find(
                            (transformation) =>
                              transformation.status !==
                              TransformationStatus.Approved,
                          );

                        if (firstNotApprovedTransformation != null) {
                          const { pathname } = resolvePath(
                            SubmissionRoutes.Review,
                            baseSubmissionPath,
                          );

                          if (requestPath !== pathname)
                            return redirect(pathname);
                        } else {
                          const { pathname } = resolvePath(
                            SubmissionRoutes.Summary,
                            baseSubmissionPath,
                          );

                          if (requestPath !== pathname)
                            return redirect(pathname);
                        }
                      },
                    )
                    .with(
                      P.union(
                        SubmissionStatus.VendorApproved,
                        SubmissionStatus.VendorRejected,

                        SubmissionStatus.OrgReview,
                        SubmissionStatus.OrgApproved,
                        SubmissionStatus.OrgRejected,

                        SubmissionStatus.ReadyForDelivery,
                        SubmissionStatus.Delivered,
                      ),
                      () => {
                        const { pathname } = resolvePath(
                          SubmissionRoutes.Summary,
                          baseSubmissionPath,
                        );

                        if (requestPath !== pathname) return redirect(pathname);
                      },
                    )
                    .exhaustive() ?? null
                );
              },
              children: [
                {
                  path: SubmissionRoutes.Normalize,
                  loader: async ({ params }) => {
                    const vendorId = Number(params.vendorId);
                    const submissionId = Number(params.submissionId);

                    const data = await ensureQueryData(
                      getSubmissionRouteQueryOptions({
                        submissionId,
                        vendorId,
                      }),
                    );

                    const fileId = data?.submission?.fileId;
                    const submissionTypeId = data?.submission?.type?.id;

                    if (!fileId) {
                      console.error("No file ID found for submission");
                      return redirect(`/vendor-submit/${params.vendorId}`);
                    }
                    if (!submissionTypeId) {
                      console.error(
                        "No submission type ID found for submission",
                      );
                      return redirect(`/vendor-submit/${params.vendorId}`);
                    }

                    return { fileId, submissionTypeId };
                  },
                  element: <NormalizeMappingPageWrapper />,
                },
                {
                  path: SubmissionRoutes.Loading,
                  element: <LoadingPage />,
                },
                {
                  path: SubmissionRoutes.Review,
                  children: [
                    {
                      path: "",
                      element: <YourExtractionsPageWrapper />,
                    },
                    {
                      path: ReviewRoutes.Validation,
                      element: <ValidationPage />,
                    },
                    {
                      path: ":transformationId",
                      element: (
                        <Suspense>
                          <EnrichmentPage />
                        </Suspense>
                      ),
                    },
                  ],
                },
                {
                  path: SubmissionRoutes.Summary,
                  element: (
                    <Suspense>
                      <SummaryPage />
                    </Suspense>
                  ),
                },
              ],
            },
          ],
        },
      ],
    },
  ] satisfies RouteObject[],
};
