import { cn } from "@/lib/cn";
import { UserProfileBubble } from "../../UserProfileBubble";
import { H1, Muted } from "@/components/ui/typography";

type Props = {
  title: string;
  description: string | JSX.Element;
  className?: string;
  showUserProfileBubble?: boolean;
};

export const Header = ({
  title,
  description,
  className,
  showUserProfileBubble = false,
}: Props) => {
  return (
    <header className={cn("relative w-full px-6", className)}>
      {showUserProfileBubble && (
        <div className="absolute right-6 top-6">
          <UserProfileBubble />
        </div>
      )}

      <div className="flex w-full flex-col items-center justify-center">
        <div className="max-w-prose">
          <H1 className="text-center">{title}</H1>
          <Muted className="mt-6 text-center">{`${description}`}</Muted>
        </div>
      </div>
    </header>
  );
};
