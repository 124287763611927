import {
  ActiveFilter,
  filterOperationOptions,
  FilterOperationOptions,
  FilterOption,
  TableFilterProvider,
} from "../table/TableFiltersContext";
import { SubmissionsTable, SubmissionsTableProps } from "./SubmissionsTable";
import { useGetSubmissionsSearchVarsFromActiveFilters } from "./useGetSubmissionsSearchVarsFromActiveFilters";
import { useGetSubmissionTypeaheadItems } from "./useGetSubmissionTypeaheadItems";

export enum SubmissionFilterOptions {
  Status = "status",
  Type = "type",
  Vendor = "vendor",
}

export const defaultSubmissionsFilterOptions: FilterOption[] = [
  {
    label: "Status",
    searchField: SubmissionFilterOptions.Status,
    selectValue: SubmissionFilterOptions.Status,
    validFilterOperations: [FilterOperationOptions.IN],
  },
  {
    label: "Type",
    searchField: SubmissionFilterOptions.Type,
    selectValue: SubmissionFilterOptions.Type,
    validFilterOperations: [FilterOperationOptions.IN],
  },
  {
    label: "Partner",
    searchField: SubmissionFilterOptions.Vendor,
    selectValue: SubmissionFilterOptions.Vendor,
    validFilterOperations: [FilterOperationOptions.CONTAINS],
  },
];

export const SubmissionsTableWrapper = (
  submissionsTableProps: SubmissionsTableProps,
) => {
  const initActiveFilters: ActiveFilter[] = [
    {
      filterOperation: filterOperationOptions[1],
      filterValues: submissionsTableProps.validStatuses ?? [],
      ...defaultSubmissionsFilterOptions[0],
    },
  ];

  return (
    <TableFilterProvider
      getSearchVars={useGetSubmissionsSearchVarsFromActiveFilters}
      filterOptions={
        submissionsTableProps.filterOptions ?? defaultSubmissionsFilterOptions
      }
      useTypeaheadHook={useGetSubmissionTypeaheadItems}
      additionalProps={{
        validStatuses: submissionsTableProps.validStatuses,
      }}
      initActiveFilters={initActiveFilters}
    >
      <SubmissionsTable {...submissionsTableProps} />
    </TableFilterProvider>
  );
};
