import { useState } from "react";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { HoverBorderGradient } from "@/components/ui/hover-border-gradient";
import { cn } from "@/lib/cn";
import { easeSnappy } from "@/lib/motion";
import { Header } from "../submission/:submissionId/Header";
import { SubmissionTypeUploadGrid } from "./SubmissionTypeUploadGrid";
import { VendorSubmissionsToggleTables } from "./VendorSubmissionsToggleTables";
import {
  useCurrentUserQuery,
  useGetUserPartnerIdFromSession,
} from "@/lib/hooks/queries/User";
import { UserProfileBubble } from "../UserProfileBubble";

export const VendorSubmitPage = () => {
  const [showTypes, setShowTypes] = useState(true);
  const [parent] = useAutoAnimate({
    duration: 100,
    easing: easeSnappy,
  });

  const partnerId = useGetUserPartnerIdFromSession();
  const { data: currUser } = useCurrentUserQuery();

  return (
    <div className="max-h-screen min-h-screen content-stretch">
      <div className="absolute right-6 top-6 z-10">
        <UserProfileBubble />
      </div>
      <Header
        showUserProfileBubble={false}
        title={`${currUser?.user?.organization?.name} Submissions Portal`}
        description={`Select the type of submission you want to create and drag and drop your inventory files for ${currUser?.user?.partners?.find(
          (partner) => partner.id === partnerId,
        )
          ?.name} below. Our AI system will automatically process, validate, and prepare your data for review.`}
        className="py-6 pt-24"
      />
      <div className="flex w-full flex-col items-center md:px-16">
        <HoverBorderGradient
          as="button"
          containerClassName=""
          onClick={() => setShowTypes(!showTypes)}
          className={cn(
            "min-w-[250px] hover:bg-accent hover:text-accent-foreground",
          )}
        >
          {showTypes ? "View Past Submissions" : "Create New Submission"}
        </HoverBorderGradient>
      </div>
      <div className="mt-6 flex justify-center p-2 sm:p-4 lg:p-6" ref={parent}>
        {showTypes ? (
          <div className="max-w-prose" key="upload-grid">
            <SubmissionTypeUploadGrid />
          </div>
        ) : (
          <div className="w-full" key="tables">
            <VendorSubmissionsToggleTables />
          </div>
        )}
      </div>
    </div>
  );
};
