import { useLocalStorage } from "react-use";
import { cn } from "@/lib/cn";

import { DashboardNavItem } from "./DashboardNavItem";
import { dashboardRoutes } from "./dashboardRoutes";
import { LogoutButton } from "./LogoutButton";
import { Icons } from "@/components/Icons";

export function DashboardNav() {
  const [expanded, _setExpanded] = useLocalStorage("nav-expanded", false);

  return (
    <nav
      className={cn(
        "group relative flex h-display w-[3.5rem] flex-col justify-between gap-4 border-r border-border bg-sidebar py-6 font-semibold text-muted-foreground ",
        expanded && "w-56",
      )}
    >
      {/* Top section of nav */}
      <div className="flex flex-col items-center gap-3">
        {dashboardRoutes.children?.map((route) => {
          let path = route.path;
          if (route.id === "Settings") {
            path = `settings/user/info`;
          }

          return (
            route.showInNav && (
              <DashboardNavItem
                key={route.id}
                to={path ?? `settings/user/info`}
                name={route.id}
                Icon={route.Icon ?? Icons.settings}
              />
            )
          );
        })}
      </div>

      {/* Bottom section of nav */}
      <div className="flex flex-shrink-0 flex-col items-center justify-center gap-2 rounded-lg">
        <LogoutButton />
      </div>
    </nav>
  );
}
