import { RowClickedEvent } from "ag-grid-community";
import { useNavigate, useParams } from "react-router-dom";

import {
  defaultSubmissionsFilterOptions,
  SubmissionFilterOptions,
  SubmissionsTableWrapper,
} from "@/components/shared-components/submissions-table/SubmissionsTableWrapper";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { SubmissionStatus } from "@/gql/graphql";

import { SubmissionsColumnHeaders } from "../../../../components/shared-components/submissions-table/getSubmissionsTableRows";
// import { AutomationSettings } from "./AutomationSettings";
import { SubmissionRoutes } from "../../vendorSubmitRoutes";

export function VendorSubmissionsToggleTables() {
  const navigate = useNavigate();
  const { vendorId } = useParams();

  const onRowClick = (params: RowClickedEvent) => {
    const submissionId = params.data.Id;

    // check if the onClick happened on the download button
    const target = params?.event?.target as HTMLElement;
    const isDownloadButtonClick = target.closest(".download-button") !== null;

    // TODO: Check for failed submissions and navigate somewhere else
    // const status = params.data.Status
    if (isDownloadButtonClick == false) {
      navigate(
        `/vendor-submit/${vendorId}/submission/${submissionId}/${SubmissionRoutes.Normalize}`,
      );
    }
  };

  const filterOptions = defaultSubmissionsFilterOptions.filter(
    (option) => option.searchField !== SubmissionFilterOptions.Vendor,
  );

  return (
    <Tabs defaultValue="In Progress" className="">
      <TabsList className="">
        <TabsTrigger value="In Progress">In Progress</TabsTrigger>
        <TabsTrigger value="Submitted">Submitted</TabsTrigger>
        {/* <TabsTrigger value="Settings">Automation Settings</TabsTrigger> */}
      </TabsList>
      <TabsContent value="In Progress">
        {vendorId && (
          <SubmissionsTableWrapper
            filterOptions={filterOptions}
            vendorId={Number(vendorId)}
            onRowClick={onRowClick}
            validStatuses={[
              SubmissionStatus.AwaitingPreprocessing,
              SubmissionStatus.Pending,
              SubmissionStatus.Processing,
              SubmissionStatus.Processed,
              SubmissionStatus.Failed,
            ]}
            columnHeaders={[
              SubmissionsColumnHeaders.ID,
              SubmissionsColumnHeaders.TYPE,
              SubmissionsColumnHeaders.FILE_NAME,
              SubmissionsColumnHeaders.UPLOADED,
              SubmissionsColumnHeaders.STATUS,
              SubmissionsColumnHeaders.SUBMISSION_PERIOD,
              SubmissionsColumnHeaders.DOWNLOAD,
            ]}
          />
        )}
      </TabsContent>
      <TabsContent value="Submitted">
        {vendorId && (
          <SubmissionsTableWrapper
            vendorId={Number(vendorId)}
            onRowClick={onRowClick}
            filterOptions={filterOptions}
            validStatuses={[
              SubmissionStatus.ReadyForDelivery,
              SubmissionStatus.VendorApproved,
              SubmissionStatus.OrgReview,
              SubmissionStatus.OrgRejected,
              SubmissionStatus.OrgApproved,
              SubmissionStatus.Delivered,
            ]}
            columnHeaders={[
              SubmissionsColumnHeaders.ID,
              SubmissionsColumnHeaders.TYPE,
              SubmissionsColumnHeaders.FILE_NAME,
              SubmissionsColumnHeaders.UPLOADED,
              SubmissionsColumnHeaders.STATUS,
              SubmissionsColumnHeaders.SUBMISSION_PERIOD,
              SubmissionsColumnHeaders.DOWNLOAD,
            ]}
          />
        )}
      </TabsContent>
      {/* <TabsContent value="Settings">
        <AutomationSettings />
      </TabsContent> */}
    </Tabs>
  );
}
