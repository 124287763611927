import {
  FilterOperationOptions,
  TableFilterProvider,
} from "../table/TableFiltersContext";
import { useGetVendorsSearchVarsFromActiveFilters } from "./useGetVendorsSearchVarsFromActiveFilters";
import { useGetVendorsTypeaheadItems } from "./useGetVendorsTypeaheadItems";
import { VendorsTable } from "./VendorsTable";

const vendorFilterOptions = [
  {
    label: "Vendor ID",
    selectValue: "vendor_id",
    searchField: "vendorId",
    validFilterOperations: [
      FilterOperationOptions.CONTAINS,
      FilterOperationOptions.IN,
    ],
  },
  {
    label: "Email",
    selectValue: "email",
    searchField: "email",
    validFilterOperations: [
      FilterOperationOptions.CONTAINS,
      FilterOperationOptions.IN,
    ],
  },
  // {
  //   label: "Submissions",
  //   value: "submissions",
  //   validFilterOperations: [FilterOperationOptions.IN],
  // },
];

export const VendorsTableWrapper = () => {
  return (
    <TableFilterProvider
      getSearchVars={useGetVendorsSearchVarsFromActiveFilters}
      filterOptions={vendorFilterOptions}
      useTypeaheadHook={useGetVendorsTypeaheadItems}
    >
      <VendorsTable />
    </TableFilterProvider>
  );
};
