import { SubmissionStatus } from "@/gql/graphql";

import { UseTypeaheadHook } from "../table/TableFiltersContext";
import { useGetVendorsTypeahead } from "./filters/useGetVendorsTypeahead";
import { SubmissionFilterOptions } from "./SubmissionsTableWrapper";

enum SubmissionTypes {
  INV = "INV",
  POS = "POS",
}

export const useGetSubmissionTypeaheadItems: UseTypeaheadHook = ({
  searchField,
  typeahead = "",
  searchVars,
  activeFilter,
  validStatuses,
}) => {
  const typedValidStatuses = validStatuses as SubmissionStatus[];
  const { data: vendors } = useGetVendorsTypeahead({
    typeahead,
  });

  if (searchField === SubmissionFilterOptions.Status) {
    // the typeahead for status is all the statuses on the enum SubmissionState
    return Object.values(SubmissionStatus).reduce(
      (acc, status) => {
        if (
          typedValidStatuses.length > 0 &&
          !typedValidStatuses.includes(status)
        ) {
          return acc;
        }

        acc.push({
          label: status,
          value: status,
          selected: activeFilter?.filterValues?.includes(status) ?? false,
        });

        return acc;
      },
      [] as Array<{ label: string; value: string; selected: boolean }>,
    );
  } else if (searchField === SubmissionFilterOptions.Type) {
    return Object.values(SubmissionTypes).map((type) => ({
      label: type,
      value: type,
      selected: activeFilter?.filterValues?.includes(type) ?? false,
    }));
  } else if (searchField === SubmissionFilterOptions.Vendor) {
    return (
      vendors?.vendors.reduce(
        (acc, vendor) => {
          acc.push({
            label: vendor.name,
            value: vendor.name,
            selected:
              activeFilter?.filterValues?.includes(vendor.name) ?? false,
          });
          return acc;
        },
        [] as Array<{ label: string; value: string; selected: boolean }>,
      ) ?? []
    );
  }

  return [];
};
