import { Icons } from "@/components/Icons";
import { Avatar, AvatarFallback } from "@/components/ui/avatar";
import { Button } from "@/components/ui/button";
import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuItem,
} from "@/components/ui/dropdown-menu";
import { handleLogout } from "@/lib/handleLogout";
import {
  useCurrentUserQuery,
  useGetUserPartnerIdFromSession,
} from "@/lib/hooks/queries/User";
import { useAuth0 } from "@auth0/auth0-react";

export const UserProfileBubble = () => {
  const partnerId = useGetUserPartnerIdFromSession();
  const { data: currentUser } = useCurrentUserQuery();
  const { logout } = useAuth0();

  const userInitials =
    currentUser?.user?.firstName && currentUser?.user?.lastName
      ? `${currentUser.user.firstName[0]}${currentUser.user.lastName[0]}`
      : currentUser?.user?.email?.[0].toUpperCase();
  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-10 w-10 rounded-full">
          <Avatar>
            {/* <AvatarImage src={currentUser?.user?.avatarUrl} /> */}
            <AvatarFallback className="bg-primary">
              {userInitials}
            </AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>

      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1 py-2">
            <p className="text-xs leading-none text-muted-foreground">
              {
                currentUser?.user?.partners?.find(
                  (partner) => partner.id === partnerId,
                )?.name
              }
            </p>
            <p className="text-sm font-medium leading-none">
              {currentUser?.user?.firstName} {currentUser?.user?.lastName}
            </p>
            <p className="text-xs leading-none text-muted-foreground">
              {currentUser?.user?.email}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => handleLogout(logout)}>
          <Icons.logout className="mr-2 h-4 w-4" />
          <span>Logout</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};
